<template>
  <div class="group-row">

      <tr class="flex flex-wrap md:flex-no-wrap md:wrapped">
        <td class="flex name-cell w-1/4 items-center px-4">
        <button
          class="text-gray-600 font-semibold flex-grow-0 hover:text-primary focus:outline-none focus:text-primary transition-colors duration-200 ease"
          @click="goToGroupMembers"
        >
          {{ this.group.name}}
        </button>
        </td>
        <td
          class="bg-gray-200 md:bg-transparent w-1/3 md:w-1/6 md:flex items-center"
        >
          {{ formattedCreateDate}}
        </td>
        <td
          class="bg-gray-200 md:bg-transparent w-1/3 md:w-1/6 md:flex items-center"
        >
          {{ this.group.numberOfMembers }}
        </td>
        <td
          class="bg-gray-200 md:bg-transparent w-1/3 md:w-1/6 md:flex items-center"
        >
          {{ this.adminList }}
        </td>
        <td class="w-1/8 md:flex items-center">
          <button
            class="text-gray-600 font-semibold flex-grow-0 hover:text-primary focus:outline-none focus:text-primary transition-colors duration-200 ease"
            @click="goToGroup"
          >
            View Members
          </button>
        </td>
      </tr>
  </div>
</template>


<script>
// import Button from '@/components/Button.vue';
import { mapActions } from 'vuex';
import { getISOEightySixOOneToDateFormatted } from '../filters';

export default {
  name: 'SuperAdminUserTableRow',
  components: {
    // Button,
  },
  props: {
    /**
     * Group user to display in row
     */
    group: {
      type: Object,
    },
  },
  computed: {
    formattedCreateDate() {
      return getISOEightySixOOneToDateFormatted(this.group.createdAt);
    },
    formattedLastLoginDate() {
      return getISOEightySixOOneToDateFormatted(this.user.lastLogin);
    },
    adminList() {
      return this.group.admins.map(((admin) => {
        return `${admin.firstName} ${admin.lastName}`;
      })).join(', ');
    },
  },
  methods: {
    ...mapActions([
      'fetchGroupUsers',
    ]),
    goToGroup() {
      const params = {
        slug: this.group.slug,
        name: this.group.name,
      };
      this.$emit('members-selected', params);
    },
    goToGroupMembers() {
      const routeData = this.$router.resolve({ name: 'members', params: { group: this.group.slug } });
      window.open(routeData.href, '_blank');
    },
  },
  data() {
    return {
    };
  },
};
</script>

<style scoped>
.group-row {
  border-top: 1px solid #807C77;
}
</style>
